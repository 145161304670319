<template>
  <div id="app">
      
<!-- <keep-alive  include="MyFangke"> -->

<router-view ></router-view>
<!-- </keep-alive> -->

  
  <!-- <van-loading type="spinner" /> -->
  </div>
</template>

<script>
export default {
  
  name: 'app',
  components: {
  
  },

  created () {
   
  }
}
</script>

<style>
  @import "~assets/css/base.css";

  #app {
    position: relative;
    /* height: 100% !important; */
  }
  /* html,
body,
#app {
    height: 100%;
} */
</style>
