import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () =>
    import('views/home/Home')

const SucefulResult = () =>
    import('common/result/SucefulResult')


const profile = () =>
    import('views/profile/Profile')


const MyInfo = () =>
    import('views/profile/child-profile/my-info/MyInfo.vue')
const Audit = () =>
    import('views/profile/child-profile/my-info/Audit.vue')
const IDCard = () =>
    import('views/profile/child-profile/my-info/IDCard.vue')

const MyWages = () =>
    import('views/profile/child-profile/my-wages/MyWages.vue')


const ApplyMoney = () =>
    import('views/profile/child-profile/apply/ApplyMoney.vue')
const ApplyResult = () =>
    import('views/profile/child-profile/apply/ApplyResult.vue')

const MyBorrow = () =>
    import('views/profile/child-profile/my-borrow-list/MyBorrow.vue')


const myBorrowDetail = () =>
    import('views/profile/child-profile/my-borrow-list/MyBorrowDetail.vue')

const Application = () =>
    import('views/commit-info/Application.vue')

const Recommend = () =>
    import('views/commit-info/Recommend.vue')

const registrationEntry = () =>
    import('views/commit-info/RegistrationEntry.vue')

const WorkList = () =>
    import('views/recreuitment/workList.vue')

const WorkDetail = () =>
    import('views/recreuitment/workDetail.vue')

const help = () =>
    import('views/page-content/help.vue')

const server = () =>
    import('views/page-content/server.vue')



const login = () =>
    import('views/login/Login')

const identityBinding = () =>
    import('views/login/IdentityBinding')

// 1.安装VueRouter
Vue.use(VueRouter)

// 2.配置路由信息
const routes = [{

        path: '/',
        redirect: '/profile',
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        component: login,

    },
    {
        path: '/identityBinding',
        component: identityBinding,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sucefulResult',
        component: SucefulResult,
        meta: {
            requiresAuth: false
        }

    },
    {
        path: '/profile',
        component: profile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/myInfo',
        component: MyInfo,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/audit',
        component: Audit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/iDCard',
        component: IDCard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/applyMoney',
        component: ApplyMoney,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/applyResult',
        component: ApplyResult,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/myBorrow',
        component: MyBorrow,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/myBorrowDetail',
        component: myBorrowDetail,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/application',
        component: Application,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/recommend',
        component: Recommend,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/registrationEntry',
        component: registrationEntry,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/server',
        component: server,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/help',
        component: help,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/myWages',
        component: MyWages,
        meta: {
            requiresAuth: false
        }
    },

    {
        path: '/workList',
        component: WorkList,
        meta: {
            requiresAuth: false
        }
    },

    {
        path: '/work-detail',
        component: WorkDetail,
        meta :{
            requiresAuth:false
        }
    }
    // server

    //
    // 
]

// 3.创建路由对象
const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !localStorage.getItem('token')) {
        next('login');
    } else {
        next();
    }
})

// 4.导出
export default router
