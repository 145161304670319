import Vue from 'vue'
// import './cube-ui'
import './cube-ui'
import App from './App.vue'
import router from './router'
// import store from './store'
// import Cube from 'cube-ui'

// Vue.use(Cube)
import { Toast } from 'vant';
import { Picker } from 'vant';

Vue.use(Picker);
Vue.config.productionTip = false
Vue.use(Toast);
new Vue({
    render: h => h(App),
    router,
    [Toast.name]: Toast,
    // store
}).$mount('#app')

import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}